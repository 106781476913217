<template>
  <div class="home wrap">
		<div class="home-top">
			<img class="logo" src="../assets/img/logo.png" alt="">
			<div class="horn">
				<img src="../assets/img/home/eye.png" alt="">
				<NoticeBar scrollable background="transparent" color="#FEFEFE" :text="monthViews" :speed="40"></NoticeBar>
			</div>
		</div>
		<div class="home-helper">
			<img class="home-helper-title" src="../assets/img/home/title.png" alt="" />
			<img class="home-helper-bg" src="../assets/img/home/body.png" alt="" />
			<img @click="toPath('/brief')" class="home-helper-btn" src="../assets/img/home/brief.png" alt="" />
			<!-- <img @click="toPath('/agreement')" class="home-state-btn" src="../assets/img/home/state.png" alt="" /> -->
			<!-- <img @click="toPath('/disclaimer')" class="home-disclaimer-btn" src="../assets/img/home/disclaimer.png" alt="" /> -->
		</div>
    <ul class="home-menu">
			<li @click="toPath('/diacrisis')"><img src="../assets/img/home/diagnosis.png" alt=""></li>
			<li @click="toPath('/tools')"><img src="../assets/img/home/assess.png" alt=""></li>
			<li @click="toPath('/road')"><img src="../assets/img/home/road.png" alt=""></li>
			<!-- <li @click="toToast"><img src="../assets/img/home/document.png" alt=""></li> -->
		</ul>

		<div class="collect-shade" @click="onStep()" v-if="shadeState">
			<div class="step-1" v-show="step == 1">
				<img class="step-1-img1" src="../assets/img/collect/1-1.png" alt="">
				<img class="step-1-img2" src="../assets/img/collect/1.png" alt="">
			</div>
			<div class="step-2" v-show="step == 2">
				<img class="step-2-img1" src="../assets/img/collect/2-1.png" alt="">
				<img class="step-2-img2" src="../assets/img/collect/2.png" alt="">
			</div>
			<div class="step-3" v-show="step == 3">
				<img class="step-3-img1" src="../assets/img/collect/3-1.png" alt="">
				<img class="step-3-img2" src="../assets/img/collect/3.png" alt="">
			</div>
		</div>
		<div class="page-btm"><span @click="toPath('/agreement')">用户知情同意书</span><span @click="toPath('/disclaimer')">免责声明条款</span></div>
  </div>
</template>

<script>
import { Toast, NoticeBar } from 'vant'
import { getMonthViews } from '../common/api/index'
export default {
  data() {
    return {
			step: 1,
			shadeState: false,
			monthViews: ''
		};
  },
  created() {
		// let history = localStorage.getItem('historyInfo')
		// if (!history) {
		// 	this.$router.push('/brief')
		// 	localStorage.setItem('historyInfo', 1)
		// }

		let msdcollect = localStorage.getItem('msdcollect')
		if (!msdcollect) {
			this.shadeState = true
		}

		// 访问记录
		if (this.$store.state.openid) {
			this.$Common._record({openid: this.$store.state.openid, module_id: 1, page: this.$route.path})
		}

		this.getViews()
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取总访问量
		 */
		 getViews () {
			let that = this
			getMonthViews({}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					that.monthViews = res.data.total_views
				} else {
					Toast(res.msg)
				}
			})
		},

		toPath (path) {
			if (!this.$store.state.openid) {
				let baseURL = this.$store.state.baseURL
				window.location.replace(baseURL + '/front/msd-auth/authorize');
				return false;
			}

			if (!this.$store.state.consent) {
				this.$router.push('/agreement');
				return false;
			}

			this.$router.push(path)
		},
		toToast () {
			Toast({
				message: '暂未开启',
				className: 'submit-tip'
			})
		},
		onStep () {
			if (this.step < 3) {
				this.step++
			} else {
				this.shadeState = false
				localStorage.setItem('msdcollect', true)
			}
		}
	},
  components: {
		NoticeBar
	},
};
</script>

<style scoped lang="scss">
.page-btm{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	font-size: 22px;
	// font-weight: bold;
	margin-top: 40px;
	position: fixed;
	bottom: 60px;
	left: 0;
	z-index: 2;
	span{
		margin-left: 20px;
		text-decoration: underline;

		&:first-child{
			margin-left: 0;
		}
	}
}
.home-top{
	width: 100%;
	display: flex;
	align-items: center;
	.logo{
		width: 157px;
		margin: 10px 0 0 10px;
	}
	.horn{
		// position: absolute;
		// top: 20px;
		// right: 0;
		display: flex;
		align-items: center;
		color: #FEFEFE;
		font-weight: bold;
		// flex: 1;
		margin-left: 20px;
		img{
			width: 26px;
			margin-right: 10px;
		}
		>div{
			height: 40px ;
			width: 230px;
			// width: 100%;
			padding: 0!important;
			font-size: 22px;		
		}
	}
}
.home{
	position: relative;

	.home-helper{
		width: 100%;
		position: relative;
		margin-top: 120px;
		background-color: #000;

		.home-helper-title{
			width: 464px;
			position: absolute;
			top: -160px;
			left: 44px;
		}

		.home-helper-bg{
			width: 74%;
			margin-left: 26%;
		}

		.home-helper-btn,.home-state-btn,.home-disclaimer-btn{
			width: 110px;
			position: absolute;
			top: 0;
			right: 0;
			pointer-events: initial;
			z-index: 99;
		}
		.home-helper-btn{
			top: -90px;
		}
		.home-state-btn{
			top: -20px;
		}
		.home-disclaimer-btn{
			top: 50px;
		}
	}

	.home-menu{
		width: 36.4%;
		position: absolute;
		bottom: 20px;
		left: 52px;
		li{
			width: 100%;
			margin-bottom: 20px;
			img{
				width: 100%;
			}
		}
	}
}
.collect-shade{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	.step-1,.step-2,.step-3{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
	}
	.step-1{
		.step-1-img1{
			width: 102px;
			position: absolute;
			top: 10px;
			right: 40px;
		}
		.step-1-img2{
			width: 70%;
			position: absolute;
			top: 250px;
			right: 15%;
		}
	}
	.step-2{
		.step-2-img1{
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.step-2-img2{
			width: 90%;
			position: absolute;
			bottom: 500px;
			left: 5%;
		}
	}
	.step-3{
		.step-3-img1{
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.step-3-img2{
			width: 76%;
			position: absolute;
			bottom: 250px;
			left: 12%;
		}
	}
}
</style>
